<template>
    <div class="card mb-0">
        <ModalKeyHistory
            :modalKeyHistory="modalKeyHistory"
            :keyUuid="keyDataCopy.uuid"
            @close="modalKeyHistory = false"
            @closed="modalKeyHistoryVisible = false"
        />

        <el-form ref="formKey" :model="keyDataCopy" label-position="top" :rules="rules" @submit.native.prevent @keyup.native.enter="updateKey">
            <el-form-item :label="$t('keys.address')">
                <el-input
                    :value="keyDataCopy.client_name + ' ' + keyDataCopy.client_surname + ' - ' +keyDataCopy.address.street + ', ' + keyDataCopy.address.post_code + ' ' + keyDataCopy.address.city"
                    disabled
                />
            </el-form-item>
            <el-form-item :label="$t('keys.name')" prop="name">
                <el-input v-model="keyDataCopy.name" />
            </el-form-item>

            <el-form-item :label="$t('keys.description')">
                <el-input
                    v-model="keyDataCopy.description"
                    type="textarea"
                    :rows="3"
                />
            </el-form-item>

            <el-form-item :label="$t('keys.owner')">
                <el-select v-model="selectedHolder" :placeholder="$t('keys.select_holder')" filterable>
                    <el-option value="office" :label="$t('keys.at_office')" />
                    <el-option value="client" :label="$t('keys.at_client')" />
                    <el-option-group :label="$t('keys.employees')">
                        <el-option
                            v-for="employee in employeesData"
                            :key="employee.uuid"
                            :label="employee.name + ' ' + employee.surname"
                            :value="employee.uuid"
                        />
                    </el-option-group>
                </el-select>
            </el-form-item>
        </el-form>
        <hr class="my-4">
        <div class="flex justify-between">
            <el-button @click="showHistory">
                {{ $t('employee_details.show_history') }}
            </el-button>
            <el-button type="primary" :loading="$wait.is(`updating.${keyDataCopy.uuid}`)" :disabled="!userCan('update keys')" @click="update">
                {{ $t('common.update') }}
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        ModalKeyHistory: () => import(/* webpackChunkName: "Key/ModalKeyHistory" */ '@/views/clients_keys/components/ModalKeyHistory'),
    },

    props: {
        keyData: {
            type: Object,
            default() {
                return {
                    keyData: {},
                };
            },
        },
    },

    data() {
        return {
            employeeUuid:       this.$route.params.uuid,
            employeesData:      [],
            selectedHolder:     null,
            selectedHolderName: null,
            selectedHolderUuid: null,
            modalKeyHistory:    false,
            keyDataCopy:        { ...this.keyData },
            rules:              {
                name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    computed: {
        holderName() {
            if (this.selectedHolder === 'client' || this.selectedHolder === 'office') {
                return this.selectedHolder;
            }
            const employeeName = this.employeesData.filter(employee => employee.uuid === this.holderUuid);

            return `${employeeName[0].name} ${employeeName[0].surname}`;
        },
        holderUuid() {
            if (this.selectedHolder === 'client' || this.selectedHolder === 'office') {
                return null;
            }
            return this.selectedHolder;
        },
    },

    created() {
        if (this.keyDataCopy.holder === 'client' || this.keyDataCopy.holder === 'office') {
            this.selectedHolder = this.keyDataCopy.holder;
        } else {
            this.selectedHolder = this.keyDataCopy.holder_uuid;
        }

        this.getEmployees();
    },

    methods: {
        async getEmployees() {
            this.$wait.start('loading.employees');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.employeesData = data;
            this.$wait.end('loading.employees');
        },

        async update() {
            await new Promise(resolve => this.$refs.formKey.validate(valid => valid && resolve()));
            this.$wait.start(`updating.${this.keyDataCopy.uuid}`);
            try {
                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.keyDataCopy.uuid}`, {
                    name:        this.keyDataCopy.name,
                    description: this.keyDataCopy.description,
                    holder:      this.holderName,
                    holder_uuid: this.holderUuid,
                    image_src:   this.keyDataCopy.image_src,
                });
                this.$wait.end(`updating.${this.keyDataCopy.uuid}`);
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
            } catch (error) {
                this.$wait.end(`updating.${this.keyDataCopy.uuid}`);
            }
        },

        showHistory() {
            this.modalKeyHistoryVisible = true;
            this.modalKeyHistory = true;
        },
    },
};
</script>
<style scoped>
.icon-key {
    font-size: 50px;
}
.el-select {
    width: 100%;
}
.remove-btn {
    height: 30px;
    width: 30px;
    padding: 0;
}
</style>
